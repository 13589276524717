<template>
  <!--公共-顶部条-->
  <div class="top-bar">
    <div class="top">
      <div class="main box">
        <div class="tips">
          {{ userInfo.nickname }}
          您好，欢迎进入云上文化云
        </div>
        <div v-if="!userInfo.uid" class="link-login">请登录</div>
      </div>
    </div>
    <div class="search-bar">
      <div class="main box">
        <nuxt-link to="/" class="logo">
          <img src="../assets/images/ic_logo02.png" alt="logo">
        </nuxt-link>
        <div class="search">
          <el-input
            v-model="search"
            placeholder="请输入内容"
            prefix-icon="el-icon-search" />
          <div class="search-btn" @click="searchByKeyWords">搜索</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <div class="main box">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
          <template v-for="(item,index) in navLists">
            <el-menu-item v-if="!item.son" :key="item.id"
                          :class="{'is-active':(item.url.includes($route.path) && $route.path != '/') || $route.path == item.url}">
              <a :href="item.url">{{ item.name }}</a>
            </el-menu-item>
            <el-submenu v-if="item.son" :key="item.id" :index="index+''" :class="{
              'is-active':(item.url.includes($route.path) && $route.path != '/') || $route.path == item.url
            }">
              <template slot="title"><a :href="item.url">{{ item.name }}</a></template>
              <el-menu-item v-for="itm in item.son" :key="itm.id">
                <a :href="itm.url"><img :src="itm.icon" :alt="itm.name" style="height: 30px;">{{ itm.name }}</a>
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopBar',
  data () {
    return {
      search: '',
      activeIndex: '0'
    }
  },
  computed: {
    userInfo () {
      return this.$store.state.user.info
    },
    navLists () {
      return this.$store.state.initData
    }
  },
  mounted () {
    this.search = this.$route.query.w
  },
  methods: {
    searchByKeyWords () {
      if (!this.search) {
        this.$message({ type: 'error', message: '请输入关键字后再搜索哦~' })
        return false
      }
      if (this.$route.name !== 'search') {
        this.$router.push({
          name: 'search',
          query: { w: this.search }
        })
        return false
      }
      if (this.$route.name === 'search') {
        this.$router.replace({
          name: 'search',
          query: { w: this.search }
        })
      }
      this.$emit('search', this.search)
    }
  }
}
</script>

<style lang="scss">
.top-bar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  z-index: 99;

  .box {
    max-width: 1200px;
    margin: 0 auto;
  }

  .top {
    background-color: #f2f2f2;
    font-size: 14px;
    color: #666;
    padding: 10px 0;

    .main {
      display: flex;
      align-items: center;

      .tips {
        margin-right: 15px;
      }

      .link-login {
        color: #7978A4;
      }

      .link-login, .link-register {
        line-height: 1;
        padding: 0 8px;
        cursor: pointer;

        &:last-child {
          border-left: 1px solid #ddd;
        }
      }
    }
  }

  .search-bar {
    padding: 30px 0;
    background: url("../assets/images/img_bg_search.jpg") no-repeat center;
    background-size: cover;

    .main {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          display: block;
          height: 60px;
        }
      }

      .search {
        $lh: 50px;
        display: flex;
        align-items: center;

        [class*=" el-icon-"] {
          font-size: 18px;
          color: $primaryColor;
          font-weight: 600;
        }

        .el-input__icon {
          line-height: $lh;
        }

        .el-input__inner {
          width: 380px;
          font-size: 16px;
          height: $lh;
          line-height: $lh;
          border-radius: 0;
          border-color: $primaryColor;
        }

        .search-btn {
          width: 94px;
          line-height: $lh;
          text-align: center;
          border-radius: 0;
          border-color: $primaryColor;
          background-color: $primaryColor;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }

  .nav {
    background-color: $primaryColor;

    .main {
      img {
        display: block;
        width: 40px;
      }

      .el-menu {
        background-color: $primaryColor;

        &.el-menu--horizontal {
          border: none;

          .el-submenu__title {
            font-size: 22px;
            color: #fff;
            font-weight: bold;
            padding: 0 35px;

            i {
              color: #fff;
              font-weight: bold;
            }

            &:focus, &:hover {
              background-color: #7978A4;
            }
          }

          .el-menu-item {
            font-size: 22px;
            color: #fff;
            font-weight: bold;
            padding: 0 35px;
          }

          > .el-menu-item.is-active, > .el-menu-item:not(.is-disabled):hover, > .el-submenu .el-submenu__title:hover {
            background-color: #7978A4;
          }

          > .el-menu-item.is-active, > .el-submenu .el-submenu__title, > .el-submenu.is-active .el-submenu__title {
            border-bottom: none;
          }
        }
      }
    }
  }
}
</style>
